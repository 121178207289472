/*
ng-switch animation
*/

.view-animation.ng-enter,
.view-animation.ng-leave {
    transition: 150ms cubic-bezier(0.420, 0.000, 1.000, 1.000) all;
    position: fixed;
    left: 0;
    top: 50px;
    width: 100%;
}

.view-animation.ng-enter {
    transform: translateX(100%);
}

.view-animation.ng-leave,
.view-animation.ng-enter.ng-enter-active {
    transform: translateX(0%);
}

.view-animation.ng-leave.ng-leave-active {
    transform: translateX(-100%);
}


/*

    START 
    custom

*/

img.header-logo {
    font-size: 20px;
    display: inline-block;
    vertical-align: middle;
    height: 35px;
    margin-top: 3px;
}

* {
    font-family: "Microsoft Yahei", "微软雅黑", STXihei, "华文细黑", sans-serif;
}

a:hover,
a:active {
    text-decoration: initial;
}

ul {
    list-style: none;
    padding: 0;
}

.app {
    background: #ffd452;
}

.navbar-absolute-top a {
    color: red;
}

.navbar-brand > span {
     line-height: 38px; 
}

.navbar-icon2 {
    display: inline-block;
    height: 20px;
    width: 30px;
    background: url('/images/mobile-specific/navigation-icons/navigation-sprite.png') no-repeat;
    background-size: 216px 65px;
    background-position: -106px -25px;
    transform: translateY(6px);
}

.navbar-app {
    background-color: #ffd452;
    color: black;
}

.navbar-app .btn,
.navbar-app .btn-navbar {
    color: black;
}

.navbar-brand {
    padding: 6px;
}

.navbar-absolute-top {
    border-width: 0;
}

.navbar-absolute-bottom {
    border-width: 0;
}


/* end fix template css  */

main.navigationTiles > section {
    width: 50%;
    float: left;
    height: 46vw;
}

main.navigationTiles > section.navigationTiles-parentTile {
    /*filter:blur(0);*/
    opacity: 1;
    /*padding-top: 20px;*/
}

main.navigationTiles > section.navigationTiles-parentTile > div.contentCover {
    background: white;
    z-index: 2;
    opacity: .4;
    pointer-events: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

main.navigationTiles > section.navigationTiles-childTile {
    height: 0;
}

main.navigationTiles > section.navigationTiles-childTile.state-collapsed {
    height: 0;
    animation: shrinkHeight 0.3s;
}

main.navigationTiles section > div.contentWrapper {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
}

main.navigationTiles section > div.contentWrapper > p {
    font-size: 1.3em;
}

main.navigationTiles > section.navigationTiles-childTile.state-display {
    height: 200px;
    animation: growHeight 0.3s;
}

@keyframes growHeight {
    0% {
        height: 0;
    }
    100% {
        height: 200px;
    }
}

@keyframes shrinkHeight {
    0% {
        height: 200px;
    }
    100% {
        height: 0;
    }
}

.mobile-gameList ul {
    list-style: none;
    padding: 0;
}

.mobile-gameList ul li {
    /*border-bottom: solid 1px #FEC54B;*/
}

.mobile-gameList ul h6 {
    text-align: center;
    margin-top: 0.3em;
    position: relative;
}

.mobile-gameList ul p span{
    border: solid 1px red;
    color: red;
}
div.wrapper-typeList > a.active {
    background-color: #FEC54B;
}

div.download-list_wrapper ul {
    margin: 0;
}

div.download-list_wrapper ul li {
    float: left;
    width: 50%;
    border: solid 1px #dddddd;
    box-sizing: border-box;
}


/*
<div class="scrollmenu">
  <a href="#home">Home</a>
  <a href="#news">News</a>
  <a href="#contact">Contact</a>
  <a href="#about">About</a>
  ...
</div>
*/

div.scrollmenu {
    overflow: auto;
    white-space: nowrap;
}

div.scrollmenu a {
    display: inline-block;
    color: black;
    text-align: center;
    padding: 14px;
    text-decoration: none;
}

.state-hidingFooter-navbar {
    height: 0!important;
    min-height: 0!important;
}

.state-hidingFooter-appBody {
    padding-bottom: 0!important;
}


/*

    member - home - navigation image

*/

.member-home-buttons {
    margin-top: 1em;
}

.member-home-buttons > div {
    text-align: center;
}

.member-home-buttons a {
    width: 90%;
    display: inline-block;
    border-radius: 3px;
    background-color: #ffd452;
    color: black;
    padding-top: 5px;
    padding-bottom: 5px;
}


/*

    member settings

*/

.member-settings > section {
    overflow: hidden;
}

.member-settings > section > div {
    overflow: hidden;
}

.member-settings > section > div > div > * {
    float: left;
    width: 100%;
    border-bottom: solid 2px #eeeeee;
    padding: 0.6em;
}

.member-settings > section > div > div > *:last-child {
    border-bottom-width: 0;
}

.member-deposit-method_choose {
    text-align: center;
}

.member-deposit-method_choose > div {
    padding-top: 1em;
    padding-bottom: 1em;
}

.member-deposit-method_choose > div.selected {
    background: #FEC54B;
}




.pattern-loader {
    border: 0.3em solid red; /* Light grey */
    border-top: 0.3em solid #FFD452; /* Blue */
    border-radius: 50%;
    width: 2em;
    height: 2em;
    animation: spin 1s linear infinite;

    position: absolute;
    right: 2em;
    top: 1em;
    z-index: 1000;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}



.pattern-imgNav {
    margin-top: 1em;
    position: relative;
    width: 100%;
}

.pattern-imgNav > img {
    pointer-events: none;
    width: 100%;
}

.pattern-imgNav > a {
    position: absolute;
    width: 33%;
    height: 50%;
    z-index: 2;
    text-indent: -2000px;
}

.pattern-imgNav > a:active {
    background-color: rgba(0, 0, 0, .5);
}

.pattern-imgNav.mod-specific-recordsPage {
    margin-top: 0;
}

.pattern-imgNav.mod-specific-recordsPage > a {
    height: 33%;
}

.pattern-imgNav > a:nth-of-type(1) {
    left: 0;
    top: 0;
}

.pattern-imgNav > a:nth-of-type(2) {
    left: 34%;
    top: 0;
}

.pattern-imgNav > a:nth-of-type(3) {
    left: 67%;
    top: 0;
}

.pattern-imgNav > a:nth-of-type(4) {
    left: 0;
    top: 50%;
}

.pattern-imgNav > a:nth-of-type(5) {
    left: 34%;
    top: 50%;
}

.pattern-imgNav > a:nth-of-type(6) {
    left: 67%;
    top: 50%;
}

.pattern-imgNav.mod-specific-recordsPage > a:nth-of-type(1) {
    left: 0;
    top: 0;
}

.pattern-imgNav.mod-specific-recordsPage > a:nth-of-type(2) {
    left: 33%;
    width: 35%;
    top: 0;
}

.pattern-imgNav.mod-specific-recordsPage > a:nth-of-type(3) {
    left: 68%;
    top: 0;
    width: 32%;
}

.pattern-imgNav.mod-specific-recordsPage > a:nth-of-type(4) {
    left: 0;
    top: 33%;
}

.pattern-imgNav.mod-specific-recordsPage > a:nth-of-type(5) {
    left: 33%;
    width: 35%;
    top: 33%;
}

.pattern-imgNav.mod-specific-recordsPage > a:nth-of-type(6) {
    left: 68%;
    top: 33%;
    width: 32%;
}

.pattern-imgNav.mod-specific-recordsPage > a:nth-of-type(7) {
    left: 0;
    top: 67%;
}

.pattern-imgNav.mod-specific-recordsPage > a:nth-of-type(8) {
    left: 33%;
    width: 35%;
    top: 67%;
}


/*

    specific pattern

*/

.pattern-imgNav.mod-specific-home > a:nth-of-type(1) {}

.pattern-imgNav.mod-specific-home > a:nth-of-type(2) {
    width: 35%;
    left: 33%;
}

.pattern-imgNav.mod-specific-home > a:nth-of-type(3) {
    left: 68%;
    width: 32%;
}

.pattern-imgNav.mod-specific-home > a:nth-of-type(5) {
    width: 35%;
    left: 33%;
}

.pattern-imgNav.mod-specific-home > a:nth-of-type(6) {
    width: 32%;
    left: 68%;
}

.member-home-footer {
    position: relative;
}

.member-home-footer > img {
    pointer-events: none;
    width: 100%;
}

.member-home-footer > .clickAddons {
    z-index: 2;
}

.member-home-footer > .visualAddons {
    z-index: -1;
}

.member-home-footer div.visualAddons a,
.member-home-footer div.clickAddons a {
    float: left;
    width: 20%;
    height: 50px;
    text-indent: -2000px;
}

.member-home-footer .visualAddons,
.member-home-footer .clickAddons {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

div.accountDetails ul li {
    display: block;
    height: 5em;
    background-color: #E6E6E6;
    margin-top: 0.4em;
    border-radius: 0.4em;
    line-height: 5em;
}

div.accountDetails ul li h4 {
    display: inline-block;
}

div.accountDetails .justified > div {
    text-align: center;
}


/*

    END 
    custom

*/

#lightbulb {
    font-size: 100px;
    display: block;
    text-align: center;
}

h1 {
    padding: 0;
    margin: 10px 0;
}

.chat-user-avatar {
    font-size: 40px;
    background: #ccc;
    padding: 5px 10px;
}

.feature-icon {
    font-size: 44px;
    padding: 0;
    line-height: 68px;
    width: 72px;
    text-align: center;
    opacity: .8;
    border: 3px solid;
    border-radius: 200px;
    height: 72px;
    margin-bottom: 20px;
}

.feature-icon.fa-gamepad {
    line-height: 60px;
}

.feature-icon.fa-tachometer {
    line-height: 60px;
}

.app-content-loading {
    text-align: center;
    height: 100%;
    width: 100%;
    background: #fff;
    position: relative;
}

.loading-spinner {
    position: absolute;
    font-size: 50px;
    left: 50%;
    top: 50%;
    margin-left: -25px;
    margin-top: -25px;
}

.carousel-example-content {
    position: absolute;
    font-size: 50px;
    line-height: 50px;
    width: 100%;
    text-align: center;
    top: 50%;
    margin-top: -50px;
}

.carousel-item {
    display: block;
    color: #444;
    background: #f4f4f4;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);
    -webkit-transition: opacity 0.3s;
    -moz-transition: opacity 0.3s;
    transition: opacity 0.3s;
    opacity: 1;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.carousel {
    overflow: visible;
}

.carousel-inner {
    position: relative;
    overflow: visible;
}

.carousel>.item,
.carousel-item {
    top: 0;
    left: 0;
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    background-size: cover;
}

.carousel-item {
    -webkit-transition: -webkit-transform 500ms;
    -moz-transition: -moz-transform 500ms;
    transition: transform 500ms;
}


/*  

    universal mod styles

*/

.pattern-list {
    width: 100%;
    float: left;
}

.mod-background-white {
    background-color: white!important;
}

.mod-padding-0 {
    padding-left: 0!important;
    padding-right: 0!important;
    padding-top: 0!important;
    padding-bottom: 0!important;
}

.mod-padding-horizontal2em {
    padding-left: 2em!important;
    padding-right: 2em!important;
}

.mod-padding-horizontalp5em {
    padding-left: 0.5em!important;
    padding-right: 0.5em!important;
}

.mod-padding-verticalp6em {
    padding-top: 0.6em!important;
    padding-bottom: 0.6em!important;
}

.mod-padding-vertical1p5em {
    padding-top: 1.5em!important;
    padding-bottom: 1.5em!important;
}

.mod-margin-bottomp4em {
    margin-bottom: 0.4em!important;
}

.mod-margin-bottomp1em {
    margin-bottom: 0.1em!important;
}

.mod-margin-bottom2em {
    margin-bottom: 2em!important;
}

.mod-margin-horizontal1em {
    margin-left: 1em!important;
    margin-right: 1em!important;
}

.mod-margin-horizontal2em {
    margin-left: 2em!important;
    margin-right: 2em!important;
}

.mod-borderR-p2em {
    border-radius: 0.2em;
}

.mod-border-none {
    border-width: 0!important;
    border: none!important;
}

.mod-color-red {
    color: red!important;
}
.mod-color-black {
    color: black!important;
}

.mod-color-white {
    color: white!important;
}

.mod-color-666666 {
    color: #666666!important;
}

.mod-height-2em {
    height: 2em!important;
}

.mod-background-mainColor {
    background-color: #ffd452!important;
}

.mod-background-secondColor {
    background-color: #FEC54B!important;
}

.mod-background-CDCDCD {
    background-color: #CDCDCD!important;
}

.mod-background-E6E5E5 {
    background-color: #E6E5E5!important;
}

.mod-background-whiteInput {
    box-shadow: inset 0 0 100px 100px white!important;
}

.mod-text-alignLeft {
    text-align: left!important;
}

.mod-text-alignRight {
    text-align: right!important;
}

.mod-text-alignCenter {
    text-align: center!important;
}

.mod-text-size1p4em {
    font-size: 1.4em!important;
}

.mod-text-sizep8em {
    font-size: 0.8em!important;
}

.mod-text-bold {
    font-weight: bold!important;
}

.mod-text-thin {
    font-weight: 100!important;
}

.mod-position-relative {
    position: relative!important;
}

.mod-position-absoluate {
    position: absolute!important;
}

.mod-width-full {
    width: 100%!important;
}


/*mod-font-size2em {
    font-size: 2em!important;
}*/

.mod-specific-fav-providerName {
    border: solid 1px black;
    font-size: 0.8em;
}


.mod-display-block {
    display: block;
}

.pattern-bankIcons-wrapper > div {
    float: left;
    /*     border: solid 2px red; */
    box-sizing: border-box;
}

.pattern-bankIcons {
    background-image: url('/images/mobile-specific/bank-tiny-icons.png');
    width: 85px;
    height: 25px;
    display: inline-block;
    cursor: pointer;
}

div.index-jackpot {
    height: 100px;
    background: url("/images/jackpot.png") no-repeat;
    background-size: 380px 70px;
    background-position: center center;
    position: relative;
}

div.index-thumbnails-wrapper {
    overflow: hidden;
    width: 100%;
}
div.index-thumbnails {
    float: left;
    width: 50%;
    margin-bottom: 1em;
}


.download-ul li{
    height: 30vw;
}

/*中国农业银行*/

.ABOC {
    background-position: -12px -1758px;
}


/*中国工商银行*/

.ICBK {
    background-position: -12px -2464px;
}


/*交通银行*/

.COMM {
    background-position: -12px -2276px;
}


/*中国建设银行*/

.PCBC {
    background-position: -12px -1947px;
}


/*中国银行*/

.BKCH {
    background-position: -12px -1897px;
}


/*招商银行*/

.CMBC {
    background-position: -15px -2181px;
    width: 75px;
}


/*广东发展银行*/

.GDBK {
    background-position: -13px -2369px;
}


/*中国邮政储蓄银行*/

.PSBC {
    background-position: -11px -2557px;
}


/*兴业银行*/

.FJIB {
    background-position: -12px -2085px;
}


/*中信银行*/

.CIBK {
    background-position: -12px -2132px;
}


/*平安银行*/

.SZCB {
    background-position: -12px -2745px;
}


/*中国光大银行*/

.EVER {
    background-position: -12px -2037px;
}


/*中国民生银行*/

.MSBC {
    background-position: -10px -2228px;
}


/*浦发银行*/

.SPDB {
    background-position: -12px -2790px;
}


/*上海银行*/

.BOSH {
    background-position: -12px -2648px;
}


/*滑润银行*/

.CRBC {
    background-position: -12px -3112px;
}
